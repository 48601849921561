 /* eslint-disable */
 import Vue from 'vue'
 import VueRouter from 'vue-router'

 Vue.use(VueRouter)

 const routes = [{
     path: '/',
     name: 'index',
     component: () =>
         import ('../views/index.vue')
 }, {
     path: '/Equipmentdetails',
     name: '设备趋势图',
     component: () =>
         import ('../views/Equipmentdetails.vue')
 }]
 const router = new VueRouter({
     mode: "history",
     routes
 })

 export default router
 /* eslint-enable */