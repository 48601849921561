  /* eslint-disable */
  import Vue from 'vue';
  import App from './App.vue';
  import router from './router';
  import store from './store';
  import dataV from '@jiaminghi/data-view';
  import BaiduMap from 'vue-baidu-map'
  import Axios from 'axios'
  import ElementUI from 'element-ui';
  import 'element-ui/lib/theme-chalk/index.css';
  import { Swipe, SwipeItem } from 'vant';

  Vue.use(Swipe);
  Vue.use(SwipeItem);
  Vue.use(ElementUI);
  Axios.interceptors.request.use(function(config) {
      return config;
  }, function(error) {
      // 对请求错误做些什么
      return Promise.reject(error);
  });
  Vue.prototype.$Http = Axios;
  Vue.use(BaiduMap, {
      ak: "6ceda5fQZzdxveR426s4rU2waw5lMf8k"
  })
  Vue.use(dataV);

  // 按需引入vue-awesome图标
  import Icon from 'vue-awesome/components/Icon';
  import 'vue-awesome/icons/chart-bar.js';
  import 'vue-awesome/icons/chart-area.js';
  import 'vue-awesome/icons/chart-pie.js';
  import 'vue-awesome/icons/chart-line.js';
  import 'vue-awesome/icons/align-left.js';
  import 'vue-awesome/icons/users.js';
  // 全局注册图标
  Vue.component('icon', Icon);

  // 适配flex
  import '@/common/flexible.js';

  // 引入全局css
  import './assets/scss/style.scss';


  //引入echart
  import echarts from 'echarts'
  Vue.prototype.$echarts = echarts

  Vue.config.productionTip = false;

  new Vue({
      router,
      store,
      render: (h) => h(App),
  }).$mount('#app');
  /* eslint-enable */